import React, { useEffect } from "react";

const AboutSection = () => {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        } else {
          entry.target.classList.remove("show");
        }
      });
    });

    const hiddenElements = document.querySelectorAll(
      ".fade_up, .fade_down, .zoom_in, .zoom_out, .fade_right, .fade_left, .flip_left, .flip_right, .flip_up, .flip_down"
    );
    hiddenElements.forEach((el) => observer.observe(el));

    return () => {
      hiddenElements.forEach((el) => observer.unobserve(el));
    };
  }, []);
  return (
    <>
      {/* <!-- ====================================== Section About ===================================== --> */}
      <section className="About-section jos" id="about">
        <div className="heading-container">
          <h2 className="section-heading-text about-me fade_up">Hakkımda.</h2>
          <div className="line"></div>
        </div>
        <p className="section-sub-text about-sub-text zoom_in">
          Ben Gizem Altay, full-stack web ve mobil uygulamaları tasarlama,
          geliştirme ve sürdürme konusunda 2 yılı aşkın deneyime sahip bir
          yazılım geliştiricisiyim. Problem çözme yeteneğim ve en yüksek
          standartlara uygun yazılım çözümleri geliştirme tutkum ile öne çıkan,
          yüksek motivasyonlu bir bireyim. En son teknolojileri takip etmekten
          ve işlerimi sektörün en ileri düzeyinde tutmaktan gurur duyuyorum.
        </p>
       
        
     
        <div className="about-detail-main">
          <p className="about-detail">Email</p>
          <p
            className="about-detail-info email"
            onClick="location.href='mailto:hello@biogi.com'"
          >
          gizemaltayis@gmail.com
          </p>
        </div>
        <div className="about-detail-main">
          <p className="about-detail">Deneyim</p>
          <p className="about-detail-info">2 yıl</p>
        </div>
        <div className="about-detail-main">
          <p className="about-detail">Freelance</p>
          <p className="about-detail-info">Aktif olarak hizmet vermekteyim.</p>
        </div>
       
        <div className="about-detail-main">
          <p className="about-detail">Diller</p>
          <p className="about-detail-info">İngilizce , Türkçe</p>

        </div>
      </section>
      {/* <!-- ====================================== Section About End ===================================== --> */}
    </>
  );
};
export default AboutSection;
