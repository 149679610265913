import React from "react";
import FigmaImg from "../assets/images/figma-img.png";
import ReactIcon from "../assets/images/react.jpeg";
import ReactNative from "../assets/images/react.jpeg";
import JavaScript from "../assets/images/javascript.jpeg";
import Next from "../assets/images/next.jpeg";

const Resume = () => {



  return (
    <>
      {/* <!-- ====================================== Section Education Experience ===================================== --> */}
      <section className="education-experience" id="resume">
        <div className="row">
          <div className="col-xxl-6 col-lg-6">
            <div className="heading-container">
              <h2 className="section-heading-text about-me fade_up">Eğitim.</h2>
              <div className="line"></div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Önlisans (Çift Anadal) - Bilgisayar Programcılığı</p>
                <p className="cursus university">
                  Bartın Üniversitesi / 2021 - 2023
                </p>
              </div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Lisans - Yönetim Bilişim Sistemleri </p>
                <p className="cursus university">
                  Bartın Üniversitesi / 2019 - 2023
                </p>
              </div>
            </div>

            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Lise</p>
                <p className="cursus university">
                  Leyla Turgut Anadolu Lisesi / 2015 - 2019
                </p>
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-lg-6">
            <div className="heading-container">
              <h2 className="section-heading-text about-me fade_up">
                Deneyimler.
              </h2>
              <div className="line"></div>
            </div>
         
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
              <p className="bachelor">Yazılım Geliştirici</p>
              <p className="cursus university">
                Türkiye Zeka Vakfı / 06/2024 - Devam ediyor
              </p>
              
            </div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">React Native Geliştirici</p>
                <p className="cursus university">
                  Bufi Teknoloji / 03/2024 - 05/2024
                </p>
              </div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>

              <div className="small_yellow_border_main">
                <p className="bachelor">Web Geliştirici</p>
                <p className="cursus university">
                  Pixelized Studio / 04/2023 - 01/2024
                </p>
              
              </div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">React Native Geliştirici</p>
                <p className="cursus university">Goidea / 01/2023 – 04/2023</p>
              </div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Frontend Geliştirici</p>
                <p className="cursus university">Goidea / 10/2022 – 01/2023</p>
              </div>
            </div>
            
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Veri Analisti - SPSS - SmartPLS</p>
                <p className="cursus university">
                  Acil İstatistik / 05/2022 – 07/2022
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ====================================== Section Education Experience End ===================================== --> */}
      {/* <!-- ====================================== Section Coding Skill ===================================== --> */}
      {/* <section className="coding-skill-section">
        <div className="heading-container">
          <h2 className="section-heading-text coding-skill-text fade_up">
            Kodlama Becerileri.
          </h2>
          <div className="line"></div>
        </div>
        <div id="progress" ref={progressRef}>
          <div data-num="79" className="progress-item fade_up">
            sd
          </div>
          <div data-num="92" className="progress-item fade_up">
            sd
          </div>
          <div data-num="85" className="progress-item fade_up">
            sd
          </div>
          <div data-num="70" className="progress-item fade_up">
            sd
          </div>
          <div data-num="76" className="progress-item fade_up">
            ds
          </div>
          <div data-num="83" className="progress-item fade_up">
            ds
          </div>
        </div>
      </section> */}
      {/* <!-- ====================================== Section Coding Skill End ===================================== --> */}
      {/* <!-- ====================================== Section Design Skill ===================================== --> */}
      <section className="design-skill-section">
        <div className="heading-container">
          <h2 className="section-heading-text design-skill-text fade_up">
            Yetenekler.
          </h2>
          <div className="line"></div>
        </div>
        <div className="design-skill-sub-section">
          <div className="design-skills-img-main flip_up">
            <img src={FigmaImg} alt="HTML-img" />
            <div className="skill-counter-main">
              <p>94%</p>
              <p>HTML, CSS</p>
            </div>
          </div>

          <div className="design-skills-img-main photoshop flip_up">
            <img src={ReactIcon} alt="React-img" />
            <div className="skill-counter-main photoshop-text">
              <p>75%</p>
              <p>React</p>
            </div>
          </div>
          <div className="design-skills-img-main adobe-xd flip_up">
            <img src={ReactNative} alt="ReactNative-img" />
            <div className="skill-counter-main adobe-xd-text">
              <p>65%</p>
              <p>React Native</p>
            </div>
          </div>

          <div className="design-skills-img-main sketch flip_up">
            <img src={JavaScript} alt="JavaScript-img" />
            <div className="skill-counter-main sketch-text">
              <p>60%</p>
              <p>JavaScript</p>
            </div>
          </div>
          <div className="design-skills-img-main invision flip_up">
            <img src={Next} alt="Next-img" />
            <div className="skill-counter-main invision-text">
              <p>50%</p>
              <p>Next</p>
            </div>
          </div>
          
        </div>
      </section>
      {/* <!-- ====================================== Section Coding Skill End ===================================== --> */}
      {/* <!-- ====================================== Section Award ===================================== --> */}
      <section className="awards-section overflow-hidden">
        <div className="heading-container">
          <h2 className="section-heading-text coding-skill-text fade_up">
            Ödüller.
          </h2>
          <div className="line"></div>
        </div>
        <div className="row awards-row">
          <div className="">
            <div className="box-item flip_up">
              <div className="flip-box">
                <div className="flip-box-front">
                  <div className="inner">
                    <div className="years-award-img">
                      <p className="award-yer">2022</p>
                    </div>
                    <p className="award-interior">
                      Üniversite Öğrencileri Araştırma Projeleri
                    </p>
                    <p className="award-winner-text">Tübitak</p>
                  </div>
                </div>
                <div className="flip-box-back">
                  <div className="inner">
                    <p className="flip-back-text">
                      2209-A - Üniversite Öğrencileri Araştırma Projeleri
                      Destekleme Programı Proje Yöneticisi / Pandemi Sürecinde
                      Uzaktan Eğitimde Farklı Ders Türlerinin Verimliliğinin
                      Belirlenmesi ve İyileştirilmesi
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ====================================== Section Award End ===================================== --> */}
    </>
  );
};
export default Resume;
