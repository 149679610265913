import React from "react";

const Blog = () => {
  return (
    <>
      {/* <!-- ====================================== Section Blogs ===================================== --> */}
      <section className="blog-section" id="blog">
        <div className="heading-container">
          <h2 className="section-heading-text coding-skill-text fade_up">
            Bloglarım.
          </h2>
          <div className="line"></div>
        </div>
        <div className="blog-main zoom_in">
          <div className="blog-img-section">
            <p className="blog-date">
              16<span>Haz</span>
            </p>
          </div>
          <div>
            <h3 className="post-date">16 Haziran 2024 | Programlama Dili</h3>
            <h4 className="steps">
              Rust'ta Temel Kavramlar: Fonksiyonlar, Bellek Yönetimi ve Sahiplik
            </h4>
            <h5 className="est">
             Bu makalede, Rust'taki temel kavramları keşfedeceğiz:
              fonksiyonlar, bellek yönetimi ve sahiplik. Fonksiyonlar hem
              parametreli hem de parametresiz olarak nasıl tanımlanır? 
            </h5>
            <div className="wrapper blog-btn">
              <a
                className="btn-hover"
                href="https://medium.com/@1gizemaltay/fundamental-concepts-in-rust-functions-memory-management-and-ownership-6e773bb0652e"
              >
              Devamını Oku
                <svg
                  className="btn-arrow-right"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M21.5621 14.6665L14.4101 7.51452L16.2957 5.62891L26.6666 15.9999L16.2957 26.3707L14.4101 24.4851L21.5621 17.3332H5.33331V14.6665H21.5621Z"
                    fill="#0F141C"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="blog-main zoom_in">
          <div className="blog-img-section blog-img-section2">
            <p className="blog-date">
              10<span>may</span>
            </p>
          </div>
          <div>
            <h3 className="post-date">10 Mayıs 2024 | Masaüstü Geliştirme</h3>
            <h4 className="steps">
            Rust'ta Zip Dosyası Çıkarma Adım Adım 
            </h4>
            <h5 className="est">
            Bir zip dosyasını ayıklamak, özellikle dosya arşivleriyle uğraşırken yazılım geliştirmede yaygın bir görevdir. Bu makalede, Rust'ta zip arşivinden dosya çıkarmak için basit bir programın nasıl oluşturulacağını keşfedeceğiz.
            </h5>
            <div className="wrapper blog-btn">
              <a className="btn-hover" href="#blog-popup">
                Devamını Oku
                <svg
                  className="btn-arrow-right"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M21.5621 14.6665L14.4101 7.51452L16.2957 5.62891L26.6666 15.9999L16.2957 26.3707L14.4101 24.4851L21.5621 17.3332H5.33331V14.6665H21.5621Z"
                    fill="#0F141C"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="blog-main mb-0 zoom_in">
          <div className="blog-img-section blog-img-section3">
            <p className="blog-date">
              7<span>may</span>
            </p>
          </div>
          <div>
            <h3 className="post-date">7 Mayıs 2024 | Programlama Dili</h3>
            <h4 className="steps">
            Rust Programlamaya Giriş: Döngüleri, Temel Kavramları ve Değişkenleri Keşfetmek
            </h4>
            <h5 className="est">
            Bu makalede, Rust dilinin temel kavramlarını açıklayarak okuyucuların dilin gücünü keşfetmelerini ve etkili yazılım geliştirmeyi kolaylaştırmalarını sağlamayı amaçlıyorum.

            </h5>
            <div className="wrapper blog-btn">
              <a className="btn-hover" href="#blog-popup">
              Devamını Oku
                <svg
                  className="btn-arrow-right"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M21.5621 14.6665L14.4101 7.51452L16.2957 5.62891L26.6666 15.9999L16.2957 26.3707L14.4101 24.4851L21.5621 17.3332H5.33331V14.6665H21.5621Z"
                    fill="#0F141C"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="blog-main mb-0 zoom_in">
          <div className="blog-img-section blog-img-section3">
            <p className="blog-date">
              4<span>Ocak</span>
            </p>
          </div>
          <div>
            <h3 className="post-date">4 Ocak 2024 | Android Studio</h3>
            <h4 className="steps">
            Android Studio HAXM Kurulum Hatası
            </h4>
            <h5 className="est">
            Son zamanlarda kullanıcılar React Native için Android Studio kurulumu yaparken “HAXM yüklenmedi” hatası ile karşılaşıyorlar. Bu sorunu çözmek için gerekli adımları anlattım. 
            </h5>
            <div className="wrapper blog-btn">
              <a className="btn-hover" href="#blog-popup">
              Devamını Oku
                <svg
                  className="btn-arrow-right"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M21.5621 14.6665L14.4101 7.51452L16.2957 5.62891L26.6666 15.9999L16.2957 26.3707L14.4101 24.4851L21.5621 17.3332H5.33331V14.6665H21.5621Z"
                    fill="#0F141C"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="blog-main mb-0 zoom_in">
          <div className="blog-img-section blog-img-section3">
            <p className="blog-date">
              16<span>Tem</span>
            </p>
          </div>
          <div>
            <h3 className="post-date">16 Temmuz 2022 | Veri Tabanı</h3>
            <h4 className="steps">
            SQL KISITLAMALAR
            </h4>
            <h5 className="est">
            Bu yazımda SQL Kısıtlamalarının(Constraints) kullanımları hakkında bilgi sahibi olabileceksiniz.            </h5>
            <div className="wrapper blog-btn">
              <a className="btn-hover" href="#blog-popup">
              Devamını Oku
                <svg
                  className="btn-arrow-right"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M21.5621 14.6665L14.4101 7.51452L16.2957 5.62891L26.6666 15.9999L16.2957 26.3707L14.4101 24.4851L21.5621 17.3332H5.33331V14.6665H21.5621Z"
                    fill="#0F141C"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ====================================== Section Blogs End ===================================== --> */}
    </>
  );
};
export default Blog;
