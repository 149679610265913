import React, { useState, useEffect } from "react";
import Hankar from "../assets/images/content-img1.jpeg";
import Nero from "../assets/images/nero.jpeg";
import Goidea from "../assets/images/goidea.jpeg";

import { Link } from "react-router-dom";

const Portfolio = () => {
  useEffect(() => {
    const handleImageClick = (event) => {
      event.target.classList.toggle("full");
    };

    const galleryLinks = document.querySelectorAll(".gallery-link img");
    galleryLinks.forEach((link) => {
      link.addEventListener("click", handleImageClick);
    });

    return () => {
      galleryLinks.forEach((link) => {
        link.removeEventListener("click", handleImageClick);
      });
    };
  }, []);
  return (
    <>
      <section className="portfolio-section" id="portfolio">
        <div className="heading-container">
          <h2 className="section-heading-text coding-skill-text fade_up">
            Projeler.
          </h2>
          <div className="line"></div>
        </div>
        <div className="portfolios-group-main">
          {/* <div>
            <a href="https://www.arenilac.com/" target="_blank">
              <div className="image-container popup-btn zoom_in">
                <img src={Hankar} alt="content-img" />
              </div>
            </a>
            <p className="Corporate zoom_in">Aren İlaç</p>
          </div> */}
          <div>
           <a href="https://hankar.com.tr/" target="_blank"><div className="image-container popup-btn zoom_in">
              <img src={Hankar} alt="content-img" />
            </div></a> 
            <p className="Corporate zoom_in">Hankar Demir Çelik</p>
          </div>
          <div>
            <a href="https://www.nerogrup.com.tr/" target="_blank" ><div className="image-container popup-btn zoom_in">
              <img src={Nero} alt="content-img" />
            </div></a>
            <p className="Corporate zoom_in">Nero Grup</p>
          </div>
          <div>
            <a href="https://goidea.io/" target="_blank" ><div className="image-container popup-btn zoom_in">
              <img src={Goidea} alt="content-img" />
            </div></a>
            <p className="Corporate zoom_in">Goidea</p>
          </div>
        </div>
        <div className="wrapper view-all-btn zoom_in">
          <Link className="btn-hover" target="_blank" to="https://github.com/gizem2506">
            Tümünü Görüntüle
          </Link>
        </div>
      </section>
    </>
  );
};
export default Portfolio;
